body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'myriad-pro-light', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: rgb(255,255,255);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  text-align: center;
  font-family: 'myriad-pro-semiextended', sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 1.65em;
  margin-top: 35px;
  margin-bottom: 30px;
}

h2 {
  font-family: 'myriad-pro-semiextended', sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.4em;
}

h3 {
  font-family: 'myriad-pro-bold-semiextended', sans-serif;
  font-size: 1.1em;
  margin-top: 15px;
  margin-bottom: 7px;
}

a {
  color: #454545 !important;
}